import React from "react";
import { useParams } from "react-router-dom";
import { useFetchBlogBySlugQuery } from "../../api";
import SEO from "../SEO";
import {
  Typography,
  Container,
  Card,
  CardMedia,
  Box,
  Button,
  Link,
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RecentBlogs from "../../components/RecentBlogs/recentBlogs";

const BlogDetails = () => {
  let { slug } = useParams();
  const { data, error, isLoading } = useFetchBlogBySlugQuery(slug);
  
  function processTextWithSpans(text, spans) {
    let processedText = [];
    let currentIndex = 0;
  
    spans.forEach((span, index) => {
      const { start, end, type } = span;
  
      // Add the unformatted part of the text before the current span
      if (start > currentIndex) {
        processedText.push(text.slice(currentIndex, start));
      }
  
      // Add the formatted part of the text
       if (type === "strong") {
        processedText.push(
          <strong key={index}>{text.slice(start, end)}</strong>
        );
      } else if (type === "em") {
        processedText.push(
          <em key={index}>{text.slice(start, end)}</em>
        );
      }
  
      // Move the current index to the end of the current span
      currentIndex = end;
    });
  
    // Add the remaining unformatted text after the last span
    if (currentIndex < text.length) {
      processedText.push(text.slice(currentIndex));
    }
  
    return processedText;
  }

  if (isLoading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Loading...
        </Typography>
      </Box>
    );
  if (error)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Typography variant="h6">Error: {error.message}</Typography>
      </Box>
    );
  if (!data)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Typography variant="h6">Blog not found</Typography>
      </Box>
    );

  return (
    <Container component="article" maxWidth="md" sx={{ paddingBottom: 8 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <Button
          variant="text"
          color="primary"
          component={Link}
          href="/blogs"
          sx={{
            textTransform: "none",
            padding: 0,
            display: "flex",
            alignItems: "center",
            fontSize: "1rem",
          }}
          aria-label="Back to all Blogs"
        >
          <ArrowBackIcon sx={{ mr: 1 }} />
          Back to all Blogs
        </Button>
      </Box>
      <SEO
        title={`${data.results[0].data.header[0].text} - Panostack Blogs`}
        description={data.results[0].data.description[0].text}
        imageUrl={data.results[0].data.coverimage.url}
      />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": window.location.href,
          },
          "headline": data.results[0].data.header[0].text,
          "image": data.results[0].data.coverimage.url,
          "author": {
            "@type": "Person",
            "name": data.results[0].data.writtenby[0].text,
          },
          "datePublished": data.results[0].data.createddate,
          "publisher": {
            "@type": "Organization",
            "name": "Panostack Blogs",
            "logo": {
              "@type": "ImageObject",
              "url": "../../images/panostacklogo.png",
            },
          },
        })}
      </script>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        sx={{
          fontSize: { xs: "2rem", md: "3rem" },
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: 3,
        }}
      >
        {data.results[0].data.header[0].text}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ mb: 3, textAlign: "center" }}
      >
        Published on:{" "}
        {new Date(data.results[0].data.createddate).toLocaleDateString()}
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ textAlign: "center", marginBottom: 4, fontSize: "1.25rem" }}
      >
        {data.results[0].data.description[0].text}
      </Typography>
      {data.results[0].data.coverimage.url && (
        <Card
          sx={{
            maxWidth: { xs: "90vw", md: "85vw" },
            my: 2,
            mx: "auto",
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <CardMedia
            component="img"
            image={data.results[0].data.coverimage.url}
            alt="Cover Image"
            sx={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              borderRadius: "8px",
            }}
            loading="lazy"
          />
        </Card>
      )}
      {/* Iterate over content blocks */}
      {data.results[0].data.content.map((block, index) => {
        switch (block.type) {
          case "heading2":
            return (
              <Typography
                key={index}
                variant="h4"
                sx={{
                  wordWrap: "break-word",
                  padding: 2,
                  fontSize: { xs: "2rem", md: "2.5rem" },
                  fontWeight: "bold",
                  marginBottom: 2,
                  color: "#333",
                }}
              >
                {block.text}
              </Typography>
            );
          case "heading3":
            return (
              <Typography
                key={index}
                variant="h5"
                gutterBottom
                sx={{
                  wordWrap: "break-word",
                  padding: 1,
                  fontSize: { xs: "1.75rem", md: "2rem" },
                  fontWeight: "bold",
                  marginBottom: 2,
                  color: "#555",
                }}
              >
                {block.text}
              </Typography>
            );
            case "heading4":
    return (
      <Typography
        key={index}
        variant="h6"
        gutterBottom
        sx={{
          wordWrap: "break-word",
          padding: 1,
          fontSize: { xs: "1.5rem", md: "1.75rem" },
          fontWeight: "bold",
          marginBottom: 2,
          color: "#666",
        }}
      >
        {block.text}
      </Typography>
    );
  case "heading5":
    return (
      <Typography
        key={index}
        variant="subtitle1"
        gutterBottom
        sx={{
          wordWrap: "break-word",
          padding: 1,
          fontSize: { xs: "1.25rem", md: "1.5rem" },
          fontWeight: "bold",
          marginBottom: 2,
          color: "#777",
        }}
      >
        {block.text}
      </Typography>
    );
  case "heading6":
    return (
      <Typography
        key={index}
        variant="subtitle2"
        gutterBottom
        sx={{
          wordWrap: "break-word",
          padding: 1,
          fontSize: { xs: "1rem", md: "1.25rem" },
          fontWeight: "bold",
          marginBottom: 2,
          color: "#888",
        }}
      >
        {block.text}
      </Typography>
    );
          case "paragraph":
            return (
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  wordWrap: "break-word",
                  lineHeight: 1.75,
                  marginBottom: 3,
                  color: "#666",
                }}
              >
                {processTextWithSpans(block.text, block.spans)}
              </Typography>
            );
          case "image":
            return (
              <Card
                key={index}
                sx={{
                  maxWidth: { xs: "90vw", md: "75vw" },
                  my: 3,
                  mx: "auto",
                  borderRadius: 2,
                  boxShadow: 2,
                }}
              >
                <CardMedia
                  component="img"
                  image={block.url}
                  alt={block.alt || "Blog Image"}
                  sx={{ width: "100%", height: "auto", borderRadius: "8px" }}
                  loading="lazy"
                />
              </Card>
            );
          case "o-list-item":
            return (
              <List key={index} sx={{ listStyleType: "decimal", pl: 3 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText
                    primary={processTextWithSpans(block.text, block.spans)}
                    sx={{ color: "#555", marginBottom: 2 }}
                  />
                </ListItem>
              </List>
            );
          case "list-item":
            return (
              <List key={index} sx={{ listStyleType: "disc", pl: 3 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText
                    primary={processTextWithSpans(block.text, block.spans)}
                    sx={{ color: "#555", marginBottom: 2 }}
                  />
                </ListItem>
              </List>
            );
          default:
            return null;
        }
      })}
      {/* Author and Avatar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 4,
          borderBottom: "2px solid #ddd",
          py: 3,
        }}
      >
        <Avatar
          src={data.results[0].data.writtenbyimage.url}
          alt="Profile Picture"
          sx={{ width: 56, height: 56, marginRight: 2 }}
        />
        <Typography variant="body1" sx={{ color: "#444" }}>
          Written by: {data.results[0].data.writtenby[0].text}
        </Typography>
      </Box>
      <RecentBlogs currentSlug={slug} /> 
    </Container>
  );
};

export default BlogDetails;
