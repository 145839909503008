import React from 'react';
import { useFetchBlogsQuery } from '../../api';
import { Link } from 'react-router-dom';
import { Card, CardMedia, Typography, Box, CircularProgress } from '@mui/material';

const RecentBlogs = ({ currentSlug }) => {
  const { data, isLoading, error } = useFetchBlogsQuery(1);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Typography variant="h6">Error fetching recent blogs</Typography>
      </Box>
    );
  }

  // Filter out the current blog from the recent blogs list and limit to 3 blogs
  const recentBlogs = data.results
    .filter((blog) => blog.uid !== currentSlug)
    .slice(0, 3);

  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>Other Blogs You Might Like</Typography>
      <Box
        component="section"
        aria-label="Recent Blogs"
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' }, // 1 column on mobile, 3 on desktop
          gap: 2,
        }}
      >
        {recentBlogs.map((blog) => {
          const coverImageUrl = blog.data.coverimage?.url;
          const title = blog.data.header?.[0]?.text || 'Untitled Blog';
          const description = blog.data.description?.[0]?.text || 'No description available';
          const shortDescription = description.substring(0, 99) + '...'; // Limit to 20 characters

          return (
            <Card key={blog.uid} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Link to={`/blogs/${blog.uid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <article>
                  {coverImageUrl && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={coverImageUrl}
                      alt={`Cover image for blog post titled ${title}`}
                    />
                  )}
                  <Box sx={{ padding: 2 }}>
                    <Typography variant="h2" sx={{ fontSize: '1.25rem', fontWeight: 'bold', mb: 1 }}>
                      {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {shortDescription}
                    </Typography>
                  </Box>
                </article>
              </Link>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default RecentBlogs;
